<template>
  <div class="box-getinfo">

    <div class="getinfo-header">
      <!-- <div class="getsetups">
        <div class="left-setup">
          <van-icon name="setting-o" color="#ffffff" size="19px" />
        </div>
        <div class="right-setup">
          <van-icon name="user-circle-o" color="#ffffff" size="19px" />
        </div>
      </div> -->

      <div class="header-nav">
        <div class="nav-left">
          <div class="nav-title">{{peoGeninfos.Uname}}</div>
          <div class="nav-add">
            <van-icon name="location-o" />{{peoGeninfos.CC_Name}}
            <van-icon name="arrow" />
          </div>
        </div>
        <div class="nav-right">
          <img :src="headerimg" alt="" srcset="">
        </div>
      </div>

      <div class="header-footer">
        <div class="header-foo-left">
          <ul>
            <li>
              <router-link to="/J_fault3">
                <div>{{Zenterprise}}</div>
                <div>企业数</div>
              </router-link>
            </li>
            <li>
              <router-link to="/J_fault2">
                <div>{{FZ1}}</div>
                <div>在线企业</div>
              </router-link>
            </li>
            <li>
              <router-link to="/J_fault1">
                <div>{{FZ2}}</div>
                <div>不在线企业</div>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="header-foo-right">
          <van-icon name="setting-o" color="#ffffff" size="22px" style="margin-top:0.5rem" @click="$router.push('/setupcenter')" />
        </div>
      </div>

    </div>

    <div class="geeinfo-body">
      <div class="body-header">
        <ul>
          <li>
            <router-link to="/J_notice">
              <div>
                <van-icon name="chat" />
              </div>
              <div>通知列表</div>
            </router-link>
          </li>
          <li>
            <router-link to="/J_fault">
              <div>
                <van-icon name="warning" />
              </div>
              <div>故障列表</div>
            </router-link>
          </li>
          <li>
            <router-link to="/J_Included">
              <div>
                <van-icon name="shop-collect" />
              </div>
              <div>企业收藏</div>
            </router-link>
          </li>
          <!-- <li>
            <div>
              <svg t="1632795896277" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3697" width="25" height="25">
                <path d="M512 1024C229.248 1024 0 794.752 0 512S229.248 0 512 0s512 229.248 512 512-229.248 512-512 512z m42.666667-486.869333V298.538667C554.666667 275.328 535.552 256 512 256c-23.722667 0-42.666667 19.029333-42.666667 42.538667v256.256a41.984 41.984 0 0 0 12.202667 29.866666l121.258667 121.258667a42.368 42.368 0 0 0 60.032-0.298667 42.666667 42.666667 0 0 0 0.298666-60.032L554.666667 537.130667z" fill="#1afa29" p-id="3698"></path>
              </svg>
            </div>
            <div>历史</div>
          </li> -->

        </ul>
      </div>
      <div class="body-cell">
        <!-- <van-cell title="检查更新" value="最新版本" /> -->
        <van-cell title="资料上报" is-link to="/list_man_vote" />
        <van-cell title="店铺收藏" is-link to="/J_Included" />
        <van-cell title="帮助说明" is-link to="/infohelp/mclz_app_help" />
        <!-- <van-cell title="隐私政策" is-link /> -->
      </div>
    </div>

    <div class="box-logout">
      <van-button type="primary" block style="  border-radius: 8px;" @click="loginout">退出登录</van-button>
    </div>
  </div>
</template>

<script>
import HeaderUrl from "@/assets/login/header.png";
import { check_com_login_do, getmyuser } from "@/api/J_Sign";
import {
  Mcom_count,
  com_list_count,
} from "@/api/index";
export default {
  data() {
    return {
      headerimg: HeaderUrl,
      peoGeninfos: {},
      Zenterprise: '',
      FZ1: '',
      FZ2: '',
    };
  },
  mounted() {
    this.getLogins();
    this.getpeoInfos();
    this.ZNum_enterprise();
    this.Num_enterprise();
  },
  methods: {
    // 检测登录状态
    getLogins() {
      check_com_login_do().then((res) => {
        if (res.data.statusCode !== '200') {
          this.$router.push(`/login?replice=${this.$route.name}`)
        }
      });
    },

    // 获取个人信息
    getpeoInfos() {
      getmyuser().then((res) => {
        console.log("res", res);
        this.peoGeninfos = res.data.mydata;
      });
    },
    //获取企业总数，在线，不在线数量
    ZNum_enterprise() {
      Mcom_count().then((res) => {
        this.Zenterprise = res.data.mydata;
      }).catch((err) => {
        console.log(err)
      });
    },
    Num_enterprise() {
      com_list_count().then((res) => {
        this.FZ1 = res.data.mydata[0].value;
        this.FZ2 = res.data.mydata[1].value;
      }).catch((err) => {
        console.log(err)
      });
    },
    // 退出登录   
    loginout() {

      // console.log(this.$route)
      // return
      localStorage.removeItem('dptoken')
      this.$router.push(`/login?replice=${this.$route.name}`)
    }
  },
};
</script>

<style scoped lang="less">
.box-logout {
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.8rem;
  position: absolute;
  top: 400px;
}
.header-footer {
  width: 100%;
  display: flex;
  padding-top: 0.6rem;
  .header-foo-left {
    width: 80%;
    ul {
      width: 100%;
      display: flex;
      padding: 0 0.75rem;
      li {
        width: 33.3%;
        color: white;
        a {
          color: white;
        }
        // text-align: center;
        div:nth-child(1) {
          font-size: 19px;
          font-weight: bold;
          // text-indent: 1em;
        }
        div:nth-child(2) {
          font-size: 14px;
        }
      }
    }
  }
  .header-foo-right {
    padding: 0 0.8rem;
    width: 20%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}

.getsetups {
  width: 100%;
  display: flex;
  padding: 0.6rem 0.9rem;
  box-sizing: border-box;
  .left-setup {
    width: 50%;
  }
  .right-setup {
    width: 50%;
    text-align: right;
  }
}

.box-getinfo {
  width: 100%;
  .getinfo-header {
    width: 100%;
    height: 280px;
    background: #4a78ff;
    padding-top: 0.4rem;
  }
  .header-nav {
    width: 100%;
    // height: 60px;
    // background: greenyellow;
    display: flex;
    padding: 0 0.9rem;
    padding-top: 0.5rem;
    box-sizing: border-box;
    .nav-left {
      width: 80%;
      height: 60px;
      color: white;
      .nav-title {
        font-size: 17px;
        font-weight: bold;
        padding: 5px 0px;
        letter-spacing: 0.065rem;
      }
      .nav-add {
        margin-top: 0.08rem;
        font-size: 13px;
      }
    }
    .nav-right {
      width: 50px;
      height: 50px;
      overflow: hidden;
      border-radius: 50%;
      border: 2px solid white;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
  }
}
.grid-body {
  transform: translateY(0.5rem);
}

.geeinfo-body {
  width: 100%;
  // height: 500px;
  background: white;
  // background: red;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  // border-bottom: 1px solid red;
  position: absolute;
  top: 155px;
  .body-header {
    width: 100%;
    margin-top: 0.9rem;
    margin-bottom: 0.8rem;
    ul {
      width: 100%;
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
    }
    li {
      width: 25%;
      text-align: center;
      div:nth-child(1) {
        margin-bottom: 0.15rem;
        font-size: 26.5px;
        color: #1989fa;
      }
      div:nth-child(2) {
        font-size: 13px;
        color: #414141;
      }
    }
  }
}
</style>