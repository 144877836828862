<template>
  <div class="box-getinfo1">
    <Personal1 v-if="pDATA=='man(市管局)'"/>
    <Personal2 v-else/>
  </div>
</template>

<script>
// 市管局
import Personal1 from "./PersonalCenter1/index.vue";
// 企业
import Personal2 from "./PersonalCenter2/index.vue";
import { check_com_login_do, getmyuser } from "@/api/J_Sign";
import {
  Mcom_count,
  com_list_count,
} from "@/api/index";
export default {
  components: {
    Personal1,
    Personal2
  },
  data() {
    return {
      pDATA:''
    };
  },
  mounted() {
    this.getLogins();
  },
  methods: {
    // 检测登录状态
    getLogins() {
      check_com_login_do().then((res) => {
        this.pDATA=res.data.mydata.type;
        if(res.data.statusCode !== '200'){
          this.$router.push(`/login?replice=${this.$route.name}`)
        };
      });
    },
  },
};
</script>

<style scoped lang="less">
.box-getinfo1{
  width: 100%;
}
</style>