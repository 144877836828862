<template>
  <div class="box-getinfo">
    <div class="getinfo-header">
      <div class="header-nav">
        <div class="nav-left">
          <div class="nav-title">{{peoGeninfos.Uname}}</div>
          <div class="nav-add">
            <van-icon name="location-o" />{{Zenterprise.recity}}{{Zenterprise.recitysub}}
            <van-icon name="arrow" />
          </div>
          <!-- <router-link :to="'/xdetails/'+peoGeninfos.ComGUID">
            <div class="nav-add2">摄像头数量：{{sxt_all}}个</div>
          </router-link> -->
          <router-link :to="'/xdetails/'+peoGeninfos.ComGUID">
            <van-row style="font-size: 12px;margin-top: 20px;text-align: right;width: 100%;position: absolute;">
              <van-col span="8">摄像头总数量：{{sxt_all}}</van-col>
              <van-col span="8">在线数量：{{sxt_isok}}</van-col>
              <van-col span="8" v-if="sxt_isno!=0">不在线数量：{{sxt_isno}}</van-col>
            </van-row>
          </router-link>
        </div>
        <div class="nav-right">
          <img :src="headerimg" alt="" srcset="">
        </div>
      </div>
    </div>
    <div class="geeinfo-body">
      <div class="body-header">
      </div>
      <div class="body-cell">
        <van-cell title="通知" is-link to="/J_notice" />
        <van-cell title="证件上报" is-link to="/list_pic" />
        <van-cell title="资料上报" is-link to="/list_vote" />
        <van-cell title="帮助说明" is-link to="/infohelp/mclz_app_com_help" />
        <van-cell title="设置" is-link to="/setupcenter2" />
        <!-- <van-cell v-if="myconfig.page_title_btn_isok" :title="myconfig.page_title_btn" is-link to="/xueshi" /> -->
        <!-- <van-cell title="隐私政策" is-link /> -->
        <van-button type="primary" block style="  border-radius: 8px;margin-top: 20px;" @click="loginout">退出登录</van-button>
      </div>
    </div>

    <div class="box-logout">
    </div>
  </div>
</template>

<script>
import HeaderUrl from "@/assets/login/header.png";
import { check_com_login_do, getmyuser2, getuserinfo2, cam_list, cam_count } from "@/api/J_Sign";
import { getccconfig_json } from "@/api/index";
export default {
  data() {
    return {
      headerimg: HeaderUrl,
      peoGeninfos: {},
      Zenterprise: {},
      sxt: '',
      sxt_all: '',
      sxt_isok: '',
      sxt_isno: '',
      myconfig: {},
    };
  },
  mounted() {
    this.getLogins();
    this.getpeoInfos();
    this.ZNum_enterprise();
    this.camera();
    this.get_json();
  },
  methods: {
    // 检测登录状态
    getLogins() {
      check_com_login_do().then((res) => {
        if (res.data.statusCode !== '200') {
          this.$router.push(`/login?replice=${this.$route.name}`)
        }
      });
    },

    // 获取登录个人信息
    getpeoInfos() {
      getmyuser2().then((res) => {
        console.log("res", res);
        this.peoGeninfos = res.data.mydata;
      });
    },
    //获取登录后个人信息
    ZNum_enterprise() {
      getuserinfo2().then((res) => {
        this.Zenterprise = res.data.mydata;
      }).catch((err) => {
        console.log(err)
      });
    },
    // 摄像头数量
    camera() {
      cam_list().then((res) => {
        this.sxt = res.data.mydata.m_all
      }).catch((err) => {

      });
      cam_count().then((res) => {
        this.sxt_all = res.data.mydata.m_all
        this.sxt_isok = res.data.mydata.m_isok
        this.sxt_isno = res.data.mydata.m_isno
      }).catch((err) => {

      });
    },
    // 退出登录   
    loginout() {

      // console.log(this.$route)
      // return
      localStorage.removeItem('dptoken')
      this.$router.push(`/login?replice=${this.$route.name}`)
    },
    get_json() {
      // getccconfig_json('phone_xuexi').then((res) => {
      //   console.log('数据测试', res)
      //   this.myconfig = res.data.mydata;
      // });
    }
  },
};
</script>

<style scoped lang="less">
.box-logout {
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.8rem;
  position: absolute;
  top: 300px;
}
.header-footer {
  width: 100%;
  display: flex;
  padding-top: 0.6rem;
  .header-foo-left {
    width: 80%;
    ul {
      width: 100%;
      display: flex;
      padding: 0 0.75rem;
      li {
        width: 33.3%;
        color: white;
        // text-align: center;
        div:nth-child(1) {
          font-size: 19px;
          font-weight: bold;
          // text-indent: 1em;
        }
        div:nth-child(2) {
          font-size: 14px;
        }
      }
    }
  }
  .header-foo-right {
    padding: 0 0.8rem;
    width: 20%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}

.getsetups {
  width: 100%;
  display: flex;
  padding: 0.6rem 0.9rem;
  box-sizing: border-box;
  .left-setup {
    width: 50%;
  }
  .right-setup {
    width: 50%;
    text-align: right;
  }
}

.box-getinfo {
  width: 100%;
  .getinfo-header {
    width: 100%;
    height: 200px;
    background: #4a78ff;
    padding-top: 0.4rem;
  }
  .header-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.9rem;
    padding-top: 0.5rem;
    box-sizing: border-box;
    .nav-left {
      width: 80%;
      height: 60px;
      color: white;
      a {
        color: white;
      }
      .nav-title {
        font-size: 17px;
        font-weight: bold;
        padding: 5px 0px;
        letter-spacing: 0.065rem;
      }
      .nav-add {
        width: auto;
        margin-top: 0.08rem;
        font-size: 13px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .nav-add2 {
        width: auto;
        margin-top: 0.5rem;
        font-size: 14px;
      }
    }
    .nav-right {
      width: 50px;
      height: 50px;
      overflow: hidden;
      border-radius: 50%;
      border: 2px solid white;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
  }
}
.grid-body {
  transform: translateY(0.5rem);
}

.geeinfo-body {
  width: 100%;
  // height: 500px;
  background: white;
  // background: red;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  // border-bottom: 1px solid red;
  position: absolute;
  top: 155px;
  .body-header {
    width: 100%;
    margin-top: 0.9rem;
    margin-bottom: 0.8rem;
    ul {
      width: 100%;
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
    }
    li {
      width: 25%;
      text-align: center;
      div:nth-child(1) {
        margin-bottom: 0.15rem;
        font-size: 26.5px;
        color: #1989fa;
      }
      div:nth-child(2) {
        font-size: 13px;
        color: #414141;
      }
    }
  }
}
</style>